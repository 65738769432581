.draw {
    bottom: 0.5em;
    right: 0.5em;
    border: rgb(0, 0, 0) 2px solid;
}

.map.input#slider {
    left: 8px;
}
.input#nudgeslider {
    background-color: blue;
}

#map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

#map > div > div.totalDistance {
    font-size: small;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #000;
    color:#fff;
    position: absolute;
    bottom: 2em;
    left: 6.5em;
    z-index: 103;
}

#map > div > div.minTime {
    font-size: small;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #000;
    color:#fff;
    position: absolute;
    bottom: 0.5em;
    left: 6.5em;
    z-index: 103;
}

#map > div > div.photoTally {
    font-size: small;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #000;
    color:#fff;
    position: absolute;
    bottom: 3.5em;
    left: 6.5em;
    z-index: 103;
}

#map > div > div.aoiArea {
    font-size: small;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #000;
    color:#fff;
    position: absolute;
    bottom: 5em;
    left: 6.5em;
    z-index: 103;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-zoom.ol-unselectable.ol-control > button {
    background-color: blue;
}

#map > div > div.ol-overlaycontainer-stopevent > div.satellite.ol-selectable.ol-control {
    bottom: 16em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.satellite.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.draw.ol-selectable.ol-control {
    bottom: 12.5em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.draw.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.export-to-litchi.ol-selectable.ol-control {
    bottom: 23em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.export-to-litchi.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}


#map > div > div.ol-overlaycontainer-stopevent > div.geolocate.ol-selectable.ol-control {
    bottom: 5.5em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.geolocate.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.photo-layer.ol-selectable.ol-control {
    bottom: 19.5em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.photo-layer.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.set-home-point.ol-selectable.ol-control {
    bottom: 9em;
    right: 0.5em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
}

#map > div > div.ol-overlaycontainer-stopevent > div.set-home-point.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.direction.ol-selectable.ol-control {
    bottom: 5.5em;
    right: 4em;
    height: 2.5em;
    width: 2.5em;
    border: #000000 2px solid;
    z-index: 1001;
}

#map > div > div.ol-overlaycontainer-stopevent > div.direction.ol-selectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
    color: #000;
}

#map > div > div.ol-overlaycontainer-stopevent > input.gsdslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #ffffff;
    cursor: pointer;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible {
    top: 2em;
    mix-blend-mode: difference;
    width: fit-content;
    height: 11em;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    transition: 0ms;
}

#map > div > div.ol-overlaycontainer-stopevent > div.tools.ol-toggle.ol-button.ol-unselectable.ol-control.ol-active {
    background-color: #ffffffa1;
    border-color: #000000;
    border-style: solid;
    border-width: 2px;
    transition: 0ms;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
    bottom: 1em;
    right: 0.3em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.tools.ol-toggle.ol-button.ol-unselectable.ol-control {
    background-color: #ffffffa1;
    text-align: center;
    border-color: #000000;
    border-style: solid;
    border-width: 1px;
    transition: 0ms;
    height: 1.2em;
    width: 1.2em;
    font-size: 2em;
    bottom: 1em;
    right: 0.3em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.tools.ol-toggle.ol-button.ol-unselectable.ol-control > button {
    background: #ffffffa1;
    height: 1.2em;
    width: 1.2em;
    font-size: 1em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.menu.ol-toggle.ol-button.ol-unselectable.ol-control.ol-active {
    border-width: 2px;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-zoom.ol-unselectable.ol-control > button {
    background-color: #ffffffa1;
    color: black;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-zoom.ol-unselectable.ol-control > button:hover {
    background-color: rgba(0, 255, 106, 0.555);
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff {
    mix-blend-mode: difference;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > select {
    mix-blend-mode: difference;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    left: 0;
    text-align: center;
    color: #fff;
    border: 0px;
    width: 13em;
    height: 1.5em;
    font-size: .5em;
    margin-bottom: 1em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.rotateText {
    mix-blend-mode: difference;
    margin: 0em;
    top: 0.3em;
    left: 0.5em;
    text-anchor: center;
    width: fit-content;

    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
    text-shadow: rgb(0, 0, 0) 0px 0px 2px;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.slider {
    margin: 0em;
    top: -1em;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    background: none;
    border:#000 2px solid;
    width: 75%;
    height: 1.3em;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.slider::-webkit-slider-thumb {
    mix-blend-mode: multiply;
    margin: 0em;
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(255, 222, 77, 0.5);
    cursor: hand;
    z-index: 100;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(4) {
    margin: 0em;
    position: relative;
    padding: 0em;
    left: 10.4em;
    top: -2.1em;
    background: none;
    border-width: 0px;
    width: 20px;
    height: 20px;
    color: rgb(255, 222, 77);
    font-size: 0.5em;
    text-shadow: rgb(0, 0, 0) 0px 0px 2px;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(5) {
    margin: 0em;
    position: relative;
    padding: 0em;
    left: 11.9em;
    top: -3.15em;
    background: none;
    border-width: 0px;
    width: 1em;
    height: 1em;
    color: rgb(255, 222, 77);
    font-size: 0.5em;
    text-shadow: rgb(0, 0, 0) 0px 0px 2px;
}



#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.speedText {
    margin: 0em;
    top: -2.3em;
    left: 0.5em;
    text-anchor: center;
    width: fit-content;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
    text-shadow: rgb(0, 0, 0) 0px 0px 2px;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.speedslider {
    margin: 0em;
    top: -3.5em;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    background: none;
    border:#000 2px solid;
    width: 75%;
    height: 1.3em;
    z-index: 102;
    }
    input.speedslider::-webkit-slider-thumb {
        -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(255, 255, 255, 0.4);
    cursor: hand;
    }

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(8) {
    margin: 0em;
    position: relative;
    padding: none;
    left: 10.1em;
    top: -4.2em;
    background: none;
    border-width: 0px;
    width: 30px;
    height: 30px;
    color: rgb(255, 255, 255);
    font-size: 0.5em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(9) {
    margin: 0em;
    position: relative;
    padding: none;
    left: 11.5em;
    top: -5.35em;
    background: none;
    border-width: 0px;
    width: 30px;
    height: 30px;
    color: rgb(255, 255, 255);
    font-size: 0.5em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.gsdText {
    margin: 0em;
    top: -5.4em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;

    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.gsdslider {
    margin: 0em;
    top: -6.5em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.gsdslider::-webkit-slider-thumb {
    margin: 0em;
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(137, 255, 91, 0.5);
    cursor: hand;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(12) {
    margin: 0em;
    position: relative;
    background: none;
    border-width: 0px;
    width: 30px;
    height: 30px;
    font-size: 0.5em;
    color: rgb(137, 255, 91);
    left: 10.15em;
    top: -6.7em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(13) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -7.9em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(137, 255, 91);
    font-size: 0.5em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.nudgeText {
    margin: 0em;
    top: -8.6em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;

    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.nudgeslider {
    margin: 0em;
    top: -9.5em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.nudgeslider::-webkit-slider-thumb {
    margin: 0em;
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(89, 255, 205, 0.5);
    cursor: hand;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(16) {
    margin: 0em;
    position: relative;
    background: none;
    border-width: 0px;
    width: 30px;
    height: 30px;
    font-size: 0.5em;
    left: 10.15em;
    top: -9.24em;
    color: rgb(89, 255, 205);
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(17) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -10.4em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(89, 255, 205);
    font-size: 0.5em;

    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.sidelapText {
    margin: 0em;
    top: -11.7em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;

    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.sidelapslider {
    margin: 0em;
    top: -12.5em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.sidelapslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(237, 100, 255, 0.5);
    cursor: hand;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(20) {
    margin: 0em;
    position: relative;
    left: 10.35em;
    top: -11.7em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(237, 100, 255);
    font-size: .5em;

    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(21) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -12.9em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(237, 100, 255);
    font-size: 0.5em;

    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.frontlapText {
    margin: 0em;
    top: -14.8em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.frontlapslider {
    margin: 0em;
    top: -15.5em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.frontlapslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(255, 77, 130, 0.5);
    cursor: hand;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(24) {
    margin: 0em;
    position: relative;
    left: 10.4em;
    top: -14.2em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 77, 130);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(25) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -15.4em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 77, 130);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.heightUpdateText {
    margin: 0em;
    top: -17.8em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.densifyslider {
    margin: 0em;
    top: -18.4em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.densifyslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(255, 72, 0, 0.5);
    cursor: hand;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(28) {
    margin: 0em;
    position: relative;
    left: 10.4em;
    top: -16.7em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 72, 0);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(29) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -17.8em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 72, 0);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.gimbalText {
    margin: 0em;
    top: -21em;
    left: 0.4em;
    text-anchor: center;
    width: fit-content;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.4em;
    background: none;
    color: rgb(255, 255, 255);
    z-index: 101;
    user-select: none;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.gimbalslider {
    margin: 0em;
    top: -21.5em;
    transition: none;
    transition-delay: 0ms;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    border:rgb(0, 0, 0) 2px solid;
    background: none;
    width: 75%;
    height: 1.3em;
    z-index: 101;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input.gimbalslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 15px;
    background: rgba(255, 255, 255, 0.5);
    cursor: hand;
    z-index: 102;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(32) {
    margin: 0em;
    position: relative;
    left: 10.4em;
    top: -19.2em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 255, 255);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > button:nth-child(33) {
    margin: 0em;
    position: relative;
    left: 11.75em;
    top: -20.3em;
    background: none;
    border-width: 0px;
    width: 1.4em;
    height: 1.3em;
    color: rgb(255, 255, 255);
    font-size: 0.5em;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.imageWidthText {
    background-color: rgba(0, 0, 0, 0.5);
    top: -29em;
    left: 1.2em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > div.imageWidthDisplay {
    top: -32em;
    left: 2.4em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(27) {
    float:left;
    width: 5.5em;
    top: -27em;
    margin: 0em;
    position: relative;
    left: 1.2em;
    background: rgba(0, 0, 0, 0.500);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.4em;
    text-align: center;
    text-transform: uppercase;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(27)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(27)::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.imageHeightText {
    background-color: rgba(0, 0, 0, 0.5);
    top: -37.3em;
    left: 10.2em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > div.imageHeightDisplay {
    top: -40.3em;
    left: 11.4em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(29) {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    float:left;
    width: 5.5em;
    top: -25.45em;
    margin: 0em;
    position: relative;
    left: 8.7em;
    background: rgba(0, 0, 0, 0.507);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.4em;
    text-align: center;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(29)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(29)::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(21) {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    text-align: center;
    color:rgb(255, 255, 255);
    font-size: 0.33em;
    position: relative;
    width: fit-content;
    padding: 0;
    border: 0;
    background: rgba(0, 0, 0, 0.507);
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(21)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(21)::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.sensorWidthText {
    background-color: rgba(0, 0, 0, 0.5);
    top: -42.3em;
    left: 10.2em;
    text-anchor: left;
    width: fit-content;
    font-stretch: wider; 
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > div.sensorWidthDisplay {
    top: -45.3em;
    left: 11.4em;
    text-anchor: left;
    font-stretch: wider; 
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(31) {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    float:left;
    width: 5.5em;
    top: -25.45em;
    margin: 0em;
    position: relative;
    left: 8.7em;
    background: rgba(0, 0, 0, 0.507);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.4em;
    text-align: center;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(31)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(31)::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}


#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.focalLengthText {
    background-color: rgba(0, 0, 0, 0.5);
    top: -50.7em;
    left: 1em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > div.focalLengthDisplay {
    top: -53.7em;
    left: 2em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > p.frontlapDistanceText {
    background-color: rgba(0, 0, 0, 0.5);
    top: -46.7em;
    left: 1em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > div.frontlapDisplay {
    top: -48.7em;
    left: 3em;
    text-anchor: left;
    width: fit-content;
    margin-top: 3em;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    font-size: 0.33em;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(33) {
    -moz-appearance: textfield;
    appearance: none;
    -webkit-appearance: none;
    float:left;
    width: 5.5em;
    top: -29em;
    margin: 0em;
    position: relative;
    left: 1em;
    background: rgba(0, 0, 0, 0.507);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.4em;
    text-align: center;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(33)::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

#map > div > div.ol-overlaycontainer-stopevent > div.ol-unselectable.ol-overlay.slide-left.menu.ol-visible > menustuff > input[type=number]:nth-child(33)::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

.ol-overlay.menu
		{	width: 70%;
            margin-top: 1em;
			background: rgba(0, 0, 0, 0.4);
            font-size: 2em;
            font-family: 'Roboto', sans-serif;
            text-shadow: #000 5 5 10px;
			color: rgb(255, 255, 255);
			box-shadow: none;
			padding: 0;
			-webkit-transition: all 0.25s;
			transition: all 0.25s;
		}
		#menu
		{	padding-top: 0.1em;
			font-size: 2em;
            -webkit-transition: none;
            transition: none;
            transition-delay: 0ms;
		}
		/* menu button */
		.ol-control.menu
		{	mix-blend-mode: difference;
            bottom: 0.5em;
			left: 0.5em;
            width: 4em;
            height: 4em;
            border: #000 1px solid;
		}
        .ol-control.menu > button
		{	
            background: #ffffffa4;
            color: black;
            font-size: 2em;
            width: 1.95em;
            height: 1.95em;
		}
		.ol-control.menu i
		{	color: rgb(255, 0, 0);
		}
		.ol-zoom
		{	left: auto;
			right: 0.5em;
		}
		.ol-rotate 
		{	right: 3em;
		}
		.ol-touch .ol-rotate 
		{	right: 3.5em;
		}
		/**/
		.ol-overlay img
		{	max-width: 90%;
		}
		.data,
		.data p
		{	margin:0;
			text-align: center;
			font-size:0.9em;
		}

#searchBox {
    position: absolute;
    top: 0em;
    left: 0em;
    width: 14em;
    height: 2em;
    background: rgba(0, 0, 0, 0.705);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.8em;
    text-align: center;
    z-index: 1000;
}

#searchButton {
    position: absolute;
    top: 0em;
    left: 14.7em;
    width: 2.15em;
    height: 2.15em;
    background: rgba(0, 0, 0, 0.507);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.8em;
    text-align: center;
    z-index: 1000;
}

#resultsList {
    padding-left: 0%;
    list-style: none;
    position: absolute;
    font-family: 'Roboto', sans-serif;
    top: 1.7em;
    left: 0em;
    width: 20em;
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    border-width: 0px;
    color: rgb(255, 255, 255);
    font-size: 0.8em;
    text-align: left;
    z-index: 1000;
}

#resultsList li {
    padding: 0.2em;
    cursor: pointer;
    border: #000 1px solid;
}
